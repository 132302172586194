export default {
    calendar: {
        sidebar: {
            label: 'Calendar',
        },
        title: 'Calendar',
        addEventBtn: 'Add Event',
        showTaxEvents: 'Show Tax Events',
        enableMessage: 'Notifications for tax events are disabled.',
        disableMessage: 'Notifications for tax events are enabled.',
        eventModal: {
            title: 'Create New Event',
            eventTitleText: 'Title',
            eventTitlePlaceholder: 'Enter event title here',
            timeText: 'Select Time',
            timePlaceholder: 'Select event date',
            fromText: 'From',
            endText: 'End',
            toText: 'to',
            allDayText: 'All Day',
            repeatText: 'Repeat',
            notifyEventsText: 'Notify Events',
            notifyMeText: 'Notify Me',
            notifyValuePlaceholder: '30',
            notifyUnitPlaceholder: 'Minutes',
            descriptionText: 'Description',
            attachmentText: 'Attachment',
            createSuccessMsg: 'Event created successfully',
            updateSuccessMsg: 'Event updated successfully',
            repeatOptions: {
                never: 'Does not repeat',
                daily: 'Daily',
                weekly: 'Weekly',
                monthly: 'Monthly',
                yearly: 'Annually',
                weekday: 'Weekdays',
                custom: 'Custom',
            },
            timeOptions: {
                minutes: 'Minutes',
                hours: 'Hours',
                days: 'Days',
                weeks: 'Weeks',
            },
        },
        customRecurrenceModal: {
            title: 'Custom Recurrence',
            repeatEveryText: 'Repeat Every',
            repeatOnText: 'Repeat On',
            repeatEndText: 'Repeat End',
            endsText: 'Ends',
            repeatEndOptions: {
                never: 'Never',
                after: 'After',
                on: 'On',
            },
            repeatEndAfterPlaceholder: 'Input occurrences',
            occurrencesText: ' occurrences',
            repeatEndOnPlaceholder: 'Date',
            timeOptions: {
                days: 'Days',
                weeks: 'Weeks',
                months: 'Months',
                years: 'Years',
            },
        },
    },
}
