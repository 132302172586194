<template>
    <base-modal close class="w-[640px]" :show="show" @modal-close="closeModal">
        <template #modal-header>
            <p class="text-lg font-medium text-gray-900">
                {{ event ? event.title : $t('calendar.eventModal.title') }}
            </p>
        </template>

        <form-control-group>
            <form-text-input
                v-model="eventTitle"
                :label="$t('calendar.eventModal.eventTitleText')"
                :placeholder="$t('calendar.eventModal.eventTitlePlaceholder')"
                :error-message="errorEventTitle"
            />

            <form-control-group-row class="items-end">
                <form-date-picker
                    v-model="eventDate"
                    :label="$t('calendar.eventModal.timeText')"
                    :placeholder="$t('calendar.eventModal.timePlaceholder')"
                    single
                    auto-apply
                    :clearable="false"
                    :error-message="errorDate"
                />

                <div class="flex items-center gap-3">
                    <form-single-select
                        v-model="eventFrom"
                        :placeholder="$t('calendar.eventModal.fromText')"
                        :options="eventFromOptions"
                        :block="false"
                        hide-message
                        :error-message="errorEventFrom"
                        icon-name="line-icons:time:clock"
                        :disabled="allDay"
                    />

                    <span class="text-gray-400">
                        {{ $t('calendar.eventModal.toText') }}
                    </span>

                    <form-single-select
                        v-model="eventTo"
                        :placeholder="$t('calendar.eventModal.endText')"
                        :options="eventToOptions"
                        :block="false"
                        hide-message
                        :error-message="errorEventTo"
                        icon-name="line-icons:time:clock"
                        :disabled="allDay"
                    />
                </div>
            </form-control-group-row>

            <form-control-group-row class="min-h-[46px] items-center">
                <form-checkbox
                    :text="$t('calendar.eventModal.allDayText')"
                    v-model="allDay"
                />
                <form-single-select
                    :options="repeatOptions"
                    :placeholder="$t('calendar.eventModal.repeatText')"
                    :block="false"
                    v-model="repeatType"
                    @change="onRepeatChange"
                    :error-message="errorRepeatType"
                    :show-clear="false"
                />
            </form-control-group-row>

            <div>
                <form-control-label
                    :label="$t('calendar.eventModal.notifyEventsText')"
                />
                <div class="flex min-h-[46px] items-center gap-3">
                    <form-checkbox
                        v-model="canNotify"
                        :text="$t('calendar.eventModal.notifyMeText')"
                    />
                    <template v-if="canNotify">
                        <form-number-input
                            :placeholder="
                                $t('calendar.eventModal.notifyValuePlaceholder')
                            "
                            :block="false"
                            class="w-[70px]"
                            v-model="notificationValue"
                            :error-message="errorNotificationValue"
                        />
                        <form-single-select
                            :options="timeOptions"
                            :placeholder="
                                $t('calendar.eventModal.notifyUnitPlaceholder')
                            "
                            :block="false"
                            class="w-[115px]"
                            v-model="notificationType"
                            :error-message="errorNotificationType"
                            :show-clear="false"
                        />
                    </template>
                </div>
            </div>

            <form-control-group-row>
                <form-rich-text-editor
                    v-model="eventDescription"
                    :label="$t('calendar.eventModal.descriptionText')"
                    :error-message="errorDescription"
                />
            </form-control-group-row>

            <form-control-group-row>
                <div class="w-full">
                    <form-control-label
                        :label="$t('calendar.eventModal.attachmentText')"
                    />
                    <form-file-upload
                        v-model="documents"
                        :max-files="5"
                        :error-message="errorDocuments"
                        :custom-path="customFilePath"
                        :custom-name="customFileName"
                        @onRemoveFile="onFileRemoved"
                    />
                </div>
            </form-control-group-row>
        </form-control-group>

        <template #modal-footer>
            <div
                class="flex justify-between gap-3"
                v-if="$acl.can('create_calendar_events')"
            >
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                    :loading="loadingCreate || loadingSave"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    @click="onSave"
                    :loading="loadingCreate || loadingSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>

    <custom-recurrence-modal
        :show="showCustomRecurrenceModal"
        @modal-close="showCustomRecurrenceModal = false"
        @modal-confirm="onAddCustomRecurrence"
    />
</template>

<script setup>
import { dayjsFormat, getDate, now } from '@tenant/utils/day'
import {
    DATE_SERVER_FORMAT,
    DATE_SERVER_LONG_FORMAT,
} from '@tenant/utils/constants'
import { useForm } from 'vee-validate'
import { useApiFactory } from '@tenant/composables'
import dayjs from 'dayjs'

const props = defineProps({
    show: {
        type: Boolean,
        required: false,
    },
    event: {
        type: Object,
        required: false,
    },
})

const emit = defineEmits(['modal-close', 'event-created'])

const { uploadMultiples } = useUploadStorage()
const { t } = useI18n()
const { handleSubmit, setErrors, setValues } = useForm()
const { value: eventTitle, errorMessage: errorEventTitle } = useField('title')
const { value: eventDescription, errorMessage: errorDescription } =
    useField('description')
const { value: eventDate, errorMessage: errorDate } = useField(
    'event_date',
    undefined,
    {
        initialValue: dayjsFormat(now(), DATE_SERVER_FORMAT),
    }
)
const { value: eventFrom, errorMessage: errorEventFrom } = useField('start')
const { value: eventTo, errorMessage: errorEventTo } = useField('end')
const { value: allDay } = useField('all_day')
const { value: notificationType, errorMessage: errorNotificationType } =
    useField('notification.type')
const { value: notificationValue, errorMessage: errorNotificationValue } =
    useField('notification.value')
useField('notification.timezone', undefined, {
    initialValue: dayjs.tz.guess(),
})
const { value: canNotify } = useField('can_notify')
const { value: repeatType, errorMessage: errorRepeatType } = useField(
    'repeat',
    undefined,
    {
        initialValue: 'never',
    }
)
const { value: documents, errorMessage: errorDocuments } = useField('documents')
useField('custom_repeat.repeat_every_value', undefined, {
    initialValue: 1,
})
useField('custom_repeat.repeat_every_type', undefined, {
    initialValue: 'day',
})
useField('custom_repeat.repeat_on', undefined, {
    initialValue: [],
})
useField('custom_repeat.never_end', undefined, {
    initialValue: true,
})
useField('custom_repeat.repeat_until')
useField('custom_repeat.repeat_occurrences')

watch(
    () => allDay.value,
    (value) => {
        if (value) {
            eventFrom.value = null
            eventTo.value = null
        }
    }
)

onMounted(() => {
    if (!props.event) return
    const { extendedProps } = props.event
    setValues({
        title: extendedProps?.title || '',
        description: extendedProps?.description || '',
        event_date:
            extendedProps?.event_date ||
            dayjsFormat(extendedProps?.start, DATE_SERVER_FORMAT),
        start:
            getDate(extendedProps?.start).format(DATE_SERVER_LONG_FORMAT) ||
            null,
        end:
            getDate(extendedProps?.end).format(DATE_SERVER_LONG_FORMAT) || null,
        can_notify: !!extendedProps?.notification,
        notification: {
            type: extendedProps?.notification?.type,
            value: extendedProps?.notification?.value,
        },
        repeat: extendedProps.repeat || 'never',
        documents: extendedProps?.documents || [],
        all_day: !!extendedProps?.all_day,
        custom_repeat: {
            ...(extendedProps?.custom_repeat ?? {}),
        },
    })
})

watch(
    () => canNotify.value,
    (value) => {
        if (!value) {
            notificationType.value = null
            notificationValue.value = null
        } else {
            notificationType.value = notificationType.value ?? 'minutes'
            notificationValue.value = notificationValue.value ?? 30
        }
    }
)

const closeModal = () => {
    emit('modal-close')
}

const repeatOptions = [
    { label: t('calendar.eventModal.repeatOptions.never'), value: 'never' },
    { label: t('calendar.eventModal.repeatOptions.daily'), value: 'daily' },
    { label: t('calendar.eventModal.repeatOptions.weekly'), value: 'weekly' },
    { label: t('calendar.eventModal.repeatOptions.monthly'), value: 'monthly' },
    { label: t('calendar.eventModal.repeatOptions.yearly'), value: 'yearly' },
    { label: t('calendar.eventModal.repeatOptions.weekday'), value: 'weekday' },
    { label: t('calendar.eventModal.repeatOptions.custom'), value: 'custom' },
]

const timeOptions = [
    { label: t('calendar.eventModal.timeOptions.minutes'), value: 'minutes' },
    { label: t('calendar.eventModal.timeOptions.hours'), value: 'hours' },
    { label: t('calendar.eventModal.timeOptions.days'), value: 'days' },
    { label: t('calendar.eventModal.timeOptions.weeks'), value: 'weeks' },
]

const showCustomRecurrenceModal = ref(false)
const onRepeatChange = (value) => {
    if (value === 'custom') {
        showCustomRecurrenceModal.value = true
    }
}

const eventFromOptions = computed(() => {
    const baseDate = getDate(eventDate.value)

    const options = []
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
            const option = getTimeOption(baseDate, i, j)
            options.push(option)
        }
    }
    return options
})

const eventToOptions = computed(() => {
    const baseDate = getDate(eventDate.value)
    const selectedEventFrom = eventFrom.value ? getDate(eventFrom.value) : now()
    const startHour = selectedEventFrom.hour()
    const startMinute = selectedEventFrom.minute() + 30
    const options = []

    for (let i = startHour; i < 24; i++) {
        for (let j = i === startHour ? startMinute : 0; j < 60; j += 15) {
            const option = getTimeOption(baseDate, i, j)
            options.push(option)
        }
    }
    return options
})

const getTimeOption = (date, h, m) => {
    const hour = h.toString().padStart(2, '0')
    const minute = m.toString().padStart(2, '0')

    return {
        label: `${hour}:${minute}`,
        value: date
            .set('hour', h)
            .set('minute', m)
            .format(DATE_SERVER_LONG_FORMAT),
    }
}

watch(
    () => eventFrom.value,
    () => {
        if (!eventFrom.value) {
            eventTo.value = null
            return
        }

        eventTo.value = eventTo.value
            ? getDate(eventTo.value).format(DATE_SERVER_LONG_FORMAT)
            : getDate(eventFrom.value)
                  .add(30, 'minute')
                  .format(DATE_SERVER_LONG_FORMAT)
    }
)

const { successNotify, errorNotify } = useNotification()
const { useApiCreate, useApiSave } = useApiFactory('calendar-events')
const { execute: executeCreate, loading: loadingCreate } = useApiCreate()
const { execute: executeSave, loading: loadingSave } = useApiSave('', 'POST')

const onSave = handleSubmit(async ({ documents, ...values }) => {
    try {
        if (values.repeat !== 'custom') {
            values.custom_repeat = {}
        }

        if (values.all_day) {
            values.start = getDate(eventDate.value)
                .startOf('day')
                .format(DATE_SERVER_LONG_FORMAT)
            values.end = getDate(eventDate.value)
                .endOf('day')
                .format(DATE_SERVER_LONG_FORMAT)
        }

        const uploadKeys = await uploadMultiples(documents)

        if (props.event) {
            await executeSave(
                {
                    ...values,
                    documents: uploadKeys,
                },
                props.event.id
            )
            successNotify({
                title: t('general.success'),
                text: t('calendar.eventModal.updateSuccessMsg'),
            })
            emit('event-created')
            return
        }

        await executeCreate({
            ...values,
            documents: uploadKeys,
        })

        successNotify({
            title: t('general.success'),
            text: t('calendar.eventModal.createSuccessMsg'),
        })

        emit('event-created')
    } catch ({ errors }) {
        setErrors(errors)
    }
})

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}

const { useApiDelete } = useApiFactory('documents')
const { execute } = useApiDelete()
const onFileRemoved = async (file) => {
    const fileId = file.__proto__?.fileId
    if (!fileId) return

    try {
        await execute(fileId)
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}

const onAddCustomRecurrence = () => {
    showCustomRecurrenceModal.value = false
}
</script>
