const navigation = [
    {
        id: 'sas-calendar',
        path: 'calendar',
        label: 'calendar.sidebar.label',
        icon: 'line-icons:time:calendar-date',
        position: 100,
        permission: 'view_calendar_events',
    },
]

export default navigation
