<template>
    <base-sticky-heading
        :title="$t('calendar.title')"
        class="min-h-[69px] items-center"
    >
        <template #right>
            <div class="flex flex-col items-center gap-x-6 gap-y-1 xl:flex-row">
                <form-toggle
                    v-model="showTaxEvents"
                    :label="$t('calendar.showTaxEvents')"
                    @update:modelValue="onToggleTaxEvents"
                />
                <base-button
                    v-if="$acl.can('create_calendar_events')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="showModal = true"
                >
                    {{ $t('calendar.addEventBtn') }}
                </base-button>
            </div>
        </template>

        <calendar-event-modal
            v-if="showModal"
            :show="showModal"
            :event="selectedEvent"
            @modal-close="onModalClose"
            @event-created="onEventCreated"
        />

        <full-calendar
            ref="calendar"
            :options="calendarOptions"
            class="base-calendar"
        />
    </base-sticky-heading>
</template>

<script setup>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import { RRule } from 'rrule'

const calendar = ref(null)
const { t } = useI18n()
useHead({
    title: t('calendar.title'),
})

const calendarOptions = ref({
    plugins: [rrulePlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    slotDuration: '00:15:00',
    timeZone: 'UTC',
    slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
    },
    headerToolbar: {
        left: 'title,prev,today,next',
        end: '',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    views: {
        timeGridWeek: {
            dayHeaderContent: function (args) {
                return `${args.date.toLocaleString('default', {
                    weekday: 'short',
                })} ${args.date.getDate()}`
            },
        },
    },
    events: async function (info, successCallback, failureCallback) {
        try {
            await execute()

            successCallback(
                events.value.map((event) => {
                    return {
                        ...event,
                        textColor: '#954BAF',
                        backgroundColor: '#F4EDF7',
                        borderColor: '#F4EDF7',
                        extendedProps: {
                            ...event,
                        },
                        allDay: event.all_day,
                        start: event.start,
                        end: event.end,
                        ...getRecurrenceRule(event),
                    }
                })
            )
        } catch (error) {
            failureCallback(error)
        }
    },
    eventClick: function (info) {
        selectedEvent.value = info.event
        showModal.value = true
    },
})

const { useApiSearch, entities: events } = useApiFactory('calendar-events')
const { execute } = useApiSearch({}, false)

const { errorNotify, successNotify } = useNotification()
const showModal = ref(false)
const onEventCreated = async () => {
    try {
        showModal.value = false
        calendar.value.getApi().refetchEvents()
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                text: errorMessage,
            })
        }
    }
}

const selectedEvent = ref(null)

const onModalClose = () => {
    showModal.value = false
    selectedEvent.value = null
}

const getRecurrenceRule = (event) => {
    let rule = {}
    // If the event is not repeating, return an empty object
    if (!['never', 'custom'].includes(event.repeat)) {
        rule = {
            rrule: {
                dtstart: event.start,
                freq: event.repeat !== 'weekday' ? event.repeat : RRule.DAILY,
                byweekday:
                    event.repeat === 'weekday'
                        ? [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
                        : null,
            },
        }
    }

    // If the event is repeating, return the rrule config
    if (event.repeat === 'custom') {
        const {
            repeat_every_type,
            repeat_every_value,
            repeat_until,
            repeat_occurrences,
            repeat_on,
        } = event.custom_repeat

        // Get the frequency of the event
        const freq = getRepeatFrequency(repeat_every_type)
        rule = {
            rrule: {
                freq,
                interval: repeat_every_value,
                until: repeat_until,
                count: repeat_occurrences,
                byweekday:
                    freq === RRule.WEEKLY ? getRepeatWeekdays(repeat_on) : null,
                dtstart: event.start,
            },
        }
    }

    return rule
}

const getRepeatFrequency = (type) => {
    if (type === 'day') {
        return RRule.DAILY
    }
    if (type === 'week') {
        return RRule.WEEKLY
    }
    if (type === 'month') {
        return RRule.MONTHLY
    }
    if (type === 'year') {
        return RRule.YEARLY
    }
}

const getRepeatWeekdays = (weekdays) => {
    if (!weekdays) {
        return []
    }

    return weekdays.map((day) => {
        if (day === '1') {
            return RRule.MO
        }
        if (day === '2') {
            return RRule.TU
        }
        if (day === '3') {
            return RRule.WE
        }
        if (day === '4') {
            return RRule.TH
        }
        if (day === '5') {
            return RRule.FR
        }
        if (day === '6') {
            return RRule.SA
        }
        if (day === '7') {
            return RRule.SU
        }
    })
}

const { user } = useAuth()
const { execute: executeToggleTaxEvents } = useApi(
    'api/calendar-events/tax-events/toggle',
    'POST'
)

const showTaxEvents = computed(() => {
    if (!user.value) return false

    return !user.value?.ignore_tax_events
})

const onToggleTaxEvents = async () => {
    try {
        await executeToggleTaxEvents()
        successNotify({
            text: showTaxEvents.value
                ? t('calendar.enableMessage')
                : t('calendar.disableMessage'),
        })

        calendar.value.getApi().refetchEvents()
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                text: errorMessage,
            })
        }
    }
}
</script>

<style lang="scss">
.base-calendar {
    --fc-button-bg-color: #f2f2f2;
    --fc-button-border-color: #f2f2f2;
    --fc-button-text-color: #000;
    --fc-button-hover-bg-color: #d1d1d1;
    --fc-button-hover-border-color: #d1d1d1;
    --fc-button-hover-text-color: #000;
    --fc-button-active-bg-color: #d1d1d1;
    --fc-button-active-border-color: #d1d1d1;
    --fc-button-active-text-color: #000;
    --fc-border-color: #e4e4e4;
    --fc-neutral-bg-color: #f9f9f9;
}

.fc-toolbar-chunk > div {
    @apply flex items-center justify-between gap-2;
}

.fc {
    .fc-toolbar.fc-header-toolbar {
        @apply mb-0 p-6;
    }

    .fc-toolbar-title {
        @apply mr-4 min-w-[165px] text-xl font-medium;
    }
    .fc-button-primary {
        box-shadow: none !important;
    }

    .fc-button {
        @apply p-2 text-xs font-bold;
    }

    .fc-today-button {
        @apply px-3 font-medium;
    }

    .fc-scrollgrid-sync-inner {
        @apply justify-center p-4;
    }

    .fc-timegrid-slot-label-cushion {
        padding: 4px 16px;
        color: #afafaf;
        @apply text-sm font-light;
    }

    .fc-daygrid-day-number {
        @apply text-sm font-light;
    }

    .fc-event {
        padding: 4px;
        margin: 0 !important;
        @apply cursor-pointer text-xs font-medium;
        box-shadow: -3px 0px 12px 0px rgba(31, 31, 31, 0.02);
    }

    .fc-daygrid-event {
        @apply rounded-none;
    }

    .fc-daygrid-day-top {
        @apply flex-row;
    }

    .fc-col-header-cell {
        color: #727272;
        @apply text-sm font-normal;

        .fc-scrollgrid-sync-inner {
            @apply flex p-3;
        }
    }

    .fc-list-table td {
        padding: 16px 24px;
    }

    .fc-list-event-time,
    .fc-list-event-title {
        @apply text-sm font-normal text-gray-500;
    }

    .fc-list-event-title {
        a {
            @apply rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700;
        }
    }

    .fc-list-event-graphic {
        display: none;
    }

    .fc-button-group {
        @apply gap-0 shadow-xs;

        .fc-button {
            --fc-button-bg-color: theme('colors.white');
            --fc-button-active-bg-color: theme('colors.gray.50');
            @apply rounded-lg border border-gray-300 px-4 py-2.5 text-sm font-medium;
        }
    }

    .fc-prev-button,
    .fc-next-button {
        .fc-icon {
            font-size: 14px;
        }
    }

    .fc-scrollgrid,
    .fc-list {
        border-color: #e4e4e4;
        border-left: 0;
        border-right: 0;

        thead {
            background-color: #f9f9f9;
        }

        .fc-scrollgrid-section-header {
            @apply bg-primary-50 text-gray-900;
        }
    }

    .fc-list {
        .fc-list-day-cushion {
            @apply p-3 text-sm font-normal;
            color: #727272;
        }
    }

    .fc-today-button {
        text-transform: capitalize;
    }
}
</style>
