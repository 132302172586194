<template>
    <base-modal close :show="show" @modal-close="closeModal">
        <template #modal-header>
            <p class="text-lg font-medium text-gray-900">
                {{ $t('calendar.customRecurrenceModal.title') }}
            </p>
        </template>

        <form-control-group>
            <form-control-group-row class="items-center">
                <form-control-label
                    :label="
                        $t('calendar.customRecurrenceModal.repeatEveryText')
                    "
                />
                <form-number-input v-model="repeatValue" :block="false" />
                <form-single-select
                    v-model="repeatType"
                    :options="timeOptions"
                    :block="false"
                    :show-clear="false"
                />
            </form-control-group-row>

            <form-control-group-row v-if="showWeekSelect">
                <form-control-label
                    :label="$t('calendar.customRecurrenceModal.repeatOnText')"
                />
                <form-checkbox
                    v-model="repeatOn"
                    v-for="option in repeatOptions"
                    :value="option.value"
                    :key="option.value"
                    :text="option.label"
                    variant="text-circle"
                    size="lg"
                />
            </form-control-group-row>

            <form-control-group-row class="flex-col">
                <form-control-label
                    :label="$t('calendar.customRecurrenceModal.endsText')"
                />

                <div class="flex flex-col gap-3">
                    <div class="flex h-[42px] items-center">
                        <form-radio
                            v-model="recurrenceEnd"
                            value="never"
                            :text="
                                $t(
                                    'calendar.customRecurrenceModal.repeatEndOptions.never'
                                )
                            "
                        />
                    </div>
                    <div class="flex items-center">
                        <div class="w-[150px]">
                            <form-radio
                                v-model="recurrenceEnd"
                                value="on"
                                :text="
                                    $t(
                                        'calendar.customRecurrenceModal.repeatEndOptions.on'
                                    )
                                "
                                class="mr-12"
                            />
                        </div>
                        <form-date-picker
                            v-model="repeatUntil"
                            :clearable="false"
                            size="sm"
                            :full-width="false"
                            single
                            auto-apply
                            :disabled="recurrenceEnd !== 'on'"
                        />
                    </div>
                    <div class="flex items-center">
                        <div class="w-[150px]">
                            <form-radio
                                v-model="recurrenceEnd"
                                value="after"
                                :text="
                                    $t(
                                        'calendar.customRecurrenceModal.repeatEndOptions.after'
                                    )
                                "
                            />
                        </div>

                        <form-number-input
                            :disabled="recurrenceEnd !== 'after'"
                            v-model="occurrences"
                            :block="false"
                            size="sm"
                            :suffix="
                                $t(
                                    'calendar.customRecurrenceModal.occurrencesText'
                                )
                            "
                            :placeholder="
                                $t(
                                    'calendar.customRecurrenceModal.repeatEndAfterPlaceholder'
                                )
                            "
                            :min="1"
                            :nullValue="
                                $t(
                                    'calendar.customRecurrenceModal.repeatEndAfterPlaceholder'
                                )
                            "
                        />
                    </div>
                </div>
            </form-control-group-row>
        </form-control-group>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button @click="onSave">
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

defineProps({
    show: Boolean,
})

const emit = defineEmits(['modal-close', 'modal-confirm'])

const { t } = useI18n()

const closeModal = () => {
    emit('modal-close')
}

const timeOptions = [
    {
        label: t('calendar.customRecurrenceModal.timeOptions.days'),
        value: 'day',
    },
    {
        label: t('calendar.customRecurrenceModal.timeOptions.weeks'),
        value: 'week',
    },
    {
        label: t('calendar.customRecurrenceModal.timeOptions.months'),
        value: 'month',
    },
    {
        label: t('calendar.customRecurrenceModal.timeOptions.years'),
        value: 'year',
    },
]

const repeatOptions = [
    { label: 'M', value: '1' },
    { label: 'T', value: '2' },
    { label: 'W', value: '3' },
    { label: 'T', value: '4' },
    { label: 'F', value: '5' },
    { label: 'S', value: '6' },
    { label: 'S', value: '7' },
]

const recurrenceEnd = ref('never')
const { value: repeatValue } = useField('custom_repeat.repeat_every_value')
const { value: repeatType } = useField('custom_repeat.repeat_every_type')
const { value: repeatOn } = useField('custom_repeat.repeat_on', undefined, {
    initialValue: [],
})
const { value: neverEnd } = useField('custom_repeat.never_end')
const { value: repeatUntil } = useField('custom_repeat.repeat_until')
const { value: occurrences } = useField('custom_repeat.repeat_occurrences')

const showWeekSelect = ref(false)
watch(
    () => repeatType.value,
    (newValue) => {
        showWeekSelect.value = newValue === 'week'
    }
)

watch(
    () => recurrenceEnd.value,
    () => {
        if (recurrenceEnd.value === 'never') {
            neverEnd.value = true
            repeatUntil.value = null
            occurrences.value = null
        }

        if (recurrenceEnd.value === 'on') {
            neverEnd.value = false
            repeatUntil.value = dayjsFormat(
                repeatUntil.value ?? now(),
                DATE_SERVER_FORMAT
            )
            occurrences.value = null
        }

        if (recurrenceEnd.value === 'after') {
            neverEnd.value = false
            occurrences.value = occurrences.value ?? 1
            repeatUntil.value = null
        }
    }
)

watchEffect(() => {
    if (repeatUntil.value) {
        recurrenceEnd.value = 'on'
    }
    if (occurrences.value) {
        recurrenceEnd.value = 'after'
    }
})

const onSave = () => {
    emit('modal-confirm')
}
</script>
